.customer__topbar {
  padding: 16px 0;
  box-shadow: 2px 2px 3px rgba(27, 23, 30, 0.1);
  background-color: #fff;
  margin: 10px 0;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    li {
      list-style: none;

      a {
        background-color: #65a3ff;
        padding: 5px 10px;
        color: #fff;
        text-decoration: none;
        border-radius: 4px;

        &.active {
          background-color: #0b5ed7;
        }

        &:hover {
          background-color: #0b5ed7;
        }
      }
    }
  }
}

.customer__wrp {
  background-color: #eeeef1;
  min-height: calc(100vh - 50px);
  width: 100%;
  padding: 10px 16px;

  .address__wrp {
    padding: 20px;
    background-color: #fff;
    box-shadow: 2px 2px 3px rgba(27, 23, 30, 0.1);
  }

  iframe {
    width: 100%;
  }

  .tab__content {
    margin-top: 16px;
    form {
      .form__group {
        background-color: #fff;
        box-shadow: 2px 2px 3px rgba(27, 23, 30, 0.1);
        min-height: 544px;
        margin-bottom: 20px;
        .form__header {
          background-color: #0b5ed7;
          padding: 10px;

          span {
            color: #fff;
          }
        }

        .form_wrp{
          padding: 30px 20px;
        }
      }
    }
  }
}

.customer__tab {
  .nav {
    .nav-item {
      flex: 1 1 auto;
      a {
        color: #000;
        background-color: #dddddd;
        border-radius: 0;
        text-align: center;

        &.active {
          background-color: #0b5ed7;
          color: #fff;
        }
      }
    }
  }
}

.timing__mdoal{
  .modal-dialog{
    max-width: 570px;
  }
}
