.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f4f4f4;

    .not-found-content {
        animation: fadeIn 0.6s ease-in-out;
    }

    svg {
        font-size: 80px;
        color: #ff6b6b;
    }

    .not-found-content h1 {
        font-size: 6rem;
        color: #ff6b6b;
        margin: 20px 0;
    }

    .not-found-content p {
        font-size: 1.5rem;
        color: #555;
        margin-bottom: 30px;
    }

    .home-link {
        display: inline-block;
        padding: 10px 20px;
        font-size: 1rem;
        color: #fff;
        background-color: #333;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s;
    }

    .home-link:hover {
        background-color: #ff6b6b;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}