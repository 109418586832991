.dashboard {
  width: 100vw;
  height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;

  /* Add this CSS to your global stylesheet or a CSS module */
  .resize-handle {
    width: 10px;
    /* Adjust width as needed */
    background-color: #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: col-resize;
  }

  .resize-handle svg {
    color: #666;
    /* Set the color of the icon */
    font-size: 20px;
    /* Adjust the icon size */
  }
}

.table-tabs {
  .nav-pills {
    .nav-link {
      font-size: 14px;
      padding: 5px 10px;
      border-radius: 0;

      svg {
        transform: translateY(-2px);
      }
    }
  }
}

th {
  &.fc-datagrid-cell {
    .fc-datagrid-cell-main {
      visibility: hidden;
    }
  }
}

h2 {
  &.fc-toolbar-title {
    font-size: 16px !important;
  }
}

.calendar__view {
  .calendar__topbar {
    background-color: #dddddd;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    .nav-pills {
      width: 180px;
      gap: 5px;
      .nav-link {
        color: #fff;
        border-radius: 0;

        &.active {
          background-color: #0b5ed7;
        }
      }
    }

    input {
      height: 30px;
      padding: 0 5px;
      font-size: 12px;
      border-radius: 0;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }

    select {
      height: 30px;
      font-size: 12px;
      border-radius: 0;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }

    .calendar__topbar__btn {
      button {
        height: 30px;
        padding: 0 10px;
        border-radius: 0;

        svg {
          transform: translateY(-2px);
        }
      }
    }
  }
  .tab-content {
    margin-top: 10px;
  }
}

.vmi__tab_wrapper {
  overflow: auto;
  height: 85vh;
  .heading {
    border-bottom: 2px solid #0b5ed7;
    padding-bottom: 5px;
    h3 {
      font-size: 18px;
      color: #000;
      margin: 0;
    }
  }

  .file__upload {
    label {
      margin: 0 auto;
      padding: 20px 10px;
      height: 70px;
    }
  }
}

.trailer__table {
  min-width: 300px;
  table {
    thead {
      tr {
        th {
          border: 1px solid #ddd;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #ddd;
          font-size: 14px;
        }
      }
    }
  }
}

.order__modal {
  .modal-dialog {
    max-width: 90%;

    .modal-body {
      height: 80vh;
      overflow-y: auto;
    }
  }
}

.order__table {
  table {
    thead {
      tr {
        th {
          font-size: 14px;
          font-weight: 400;
          background-color: #f3f3f3;
          border: 1px solid #cccccc;
          .table__lg__col__head {
            padding: 8px;
          }

          .table__lg__col {
            display: flex;
            align-items: center;
            div {
              flex: 1 1 auto;
              border: 1px solid #ccc;
              padding: 8px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px;
          border: 1px solid #ccc;
          vertical-align: middle;

          .table__lg__col {
            display: flex;
            align-items: center;
            div {
              flex: 1 1 auto;
              border: 1px solid #ccc;
              padding: 8px;
            }
          }
        }
      }
    }
  }
}

.order__table__header {
  background-color: #f3f3f3;
  padding: 8px;

  .order__table__txt {
    background-color: rgba(255, 0, 0, 0.24);
    padding: 5px;
    border-radius: 4px;
    span {
      font-size: 14px;
    }
  }
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.import__inner__tab {
  .nav-link {
    border-radius: 0 !important;
    color: #000;
  }
}

// .task__tab {
//   min-width: 100vw;
// }

.task__tab__table {
  &.trip__error__table{
    table{
      tbody{
        tr{
          &:last-child{
            td{
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }
  padding-right: 10px;
  table {
    thead {
      tr {
        th {
          background-color: #f3f3f3;
          font-weight: 400;
          border: 1px solid #ccc;
          min-width: 200px;
          max-width: 250px;
          font-size: 14px;
        }
      }
    }
    tbody {
      tr {
        &:nth-child(even) {
          td {
            background-color: #f3f3f3;
          }
        }

        &:last-child{
          td{
            font-weight: 600;
          }
        }
        td {
          font-size: 14px;
          border: 1px solid #ccc;
        }
      }
    }
  }
}

.loading__tab__table{
  table{
    thead{
      tr{
        th{
          border: 1px solid #ccc;
          background-color: #f3f3f3;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    tbody{
      tr{
        td{
          border: 1px solid #ccc;
          font-size: 14px;
          vertical-align: middle;
          input{
            padding: 0 5px;
            max-width: 100px;
          }
          p{
            margin-bottom: 0;
            font-weight: 600;
          }
        }
      }
    }
  }
}
