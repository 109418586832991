$primary-color: #0b5ed7;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
  position: relative;
  font-family: "Work Sans", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-label {
  font-size: 14px !important;
}