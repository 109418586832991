.sidebar {
  background-color: #fff;
  padding: 10px;
  height: 100%;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      text-align: center;
      margin-bottom: 5px;
      a {
        text-decoration: none;
        background-color: #0b5ed7;
        padding: 5px;
        width: 60px;
        display: block;
        color: #fff;
      }
    }
  }
}
