.topbar {
  position: relative;
  background: #fefefe;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 3px solid blue;
  width: 100%;
  height: 50px;
  // width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;

  .topbar__logo {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .topbar__menu {
    ul {
      display: flex;
      list-style: none;
      gap: 0.5rem;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .topbar__right {
    display: flex;
    align-items: center;
    gap: 1rem;

    .notification__btn {
      position: relative;

      span {
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: green;
        border-radius: 50%;
        right: 0;
      }

      button {
        all: unset;
        cursor: pointer;

        svg {
          width: 26px;
          height: 26px;
        }
      }
    }

    div {
        &:nth-child(2) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            span {
                &:first-child {
                    font-size: 14px;
                    font-weight: 700;

                }

                &:last-child {
                    font-size: 10px;
                    font-weight: 400;
                }
            }
        }
    }

    .username__wrp {
      display: flex;
      align-items: center;
      button {
        svg {
          width: 14px;
          height: 14px;
        }
      }
      div {
        display: flex;
        align-items: center;
        border: 1px solid #0b5ed7;
        padding: 3px 5px;
        border-radius: 5px;
        gap: 5px;
        p {
          margin: 0;
          font-size: 12px;
        }
        span {
          font-size: 12px;
          color: #fff;
          background-color: #0b5ed7;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          text-align: center;
        }
      }
    }

    .language__wrp {
      button {
        all: unset;
        font-size: 12px;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .help__btn {
      button {
        all: unset;
        cursor: pointer;
        background-color: #000;
        border-radius: 50%;
        svg {
          width: 26px;
          height: 26px;
          fill: #fff;
        }
      }
    }
  }

  .form-select,
  .form-control {
    font-size: 12px;
    outline: none;
    height: 30px;
    line-height: 16px;

    &:focus {
      box-shadow: none;
    }
  }

  .btn {
    font-size: 14px;
    height: 30px;
    padding: 4px 10px;

    svg {
      font-size: 16px;
      // margin-right: 5px;
      transform: translateY(-2px);
    }
  }
}
